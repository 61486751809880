import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { salesforceOauth2 } from "store/actions/dashboard";
import { salesforceGenerateTokens } from "store/actions/dashboard";

const Dashboard = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);

	const useQuery = () => {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	};

	const query = useQuery();
	const code = query.get("code");

	useEffect(() => {
		if (code) dispatch(salesforceGenerateTokens(code));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code]);

	const startSalesforceOauth2 = () => {
		dispatch(salesforceOauth2());
	};

	return (
		<div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
			<Container fluid>
				<div className="header-body">
					<Row className="d-flex justify-content-center">
						<Button color="success" onClick={startSalesforceOauth2}>
							Connect Salesforce{" "}
							{user?.connectedToSalesforce &&
								"(Already connected)"}
						</Button>
					</Row>
				</div>
			</Container>
		</div>
	);
};

export default Dashboard;
