import React, { useState } from "react";
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { login } from "../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Alert from "layouts/Alert";

const Login = () => {
	const dispatch = useDispatch();
	const { isAuthenticated } = useSelector((state) => state.auth);

	const [data, setData] = useState({
		email: "",
		password: "",
	});

	const handleChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};
	const onSubmit = async () => {
		dispatch(login(data.email, data.password));
	};

	if (isAuthenticated) {
		return <Redirect to="/admin" />;
	}
	return (
		<div className="d-flex justify-content-center mt-5">
			<Col lg="5" md="7">
				<Alert />
				<Card className="bg-secondary shadow border-0">
					<CardBody className="px-lg-5 py-lg-5">
						<div className="text-center text-muted mb-4">
							<small>Sign in</small>
						</div>
						<Form>
							<FormGroup className="mb-3">
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-email-83" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Email"
										type="email"
										value={data.email}
										onChange={handleChange}
										name="email"
									/>
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-lock-circle-open" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Password"
										type="password"
										value={data.password}
										onChange={handleChange}
										name="password"
									/>
								</InputGroup>
							</FormGroup>
						</Form>
						<div className="text-center">
							<Button
								className="my-4"
								color="primary"
								disabled={!data.email || !data.password}
								onClick={onSubmit}
								type="button"
							>
								Sign in
							</Button>
						</div>
					</CardBody>
				</Card>
				<Row className="mt-3">
					<Col xs="6">
						<a
							className="text-light"
							href="#pablo"
							onClick={(e) => e.preventDefault()}
						>
							<small>
								<Link to="/register">Create new account</Link>
							</small>
						</a>
					</Col>
				</Row>
			</Col>
		</div>
	);
};

export default Login;
