import React from "react";
import moment from "moment";
import ReactDatetime from "react-datetime";
import {
	FormGroup,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
	Row,
	Button,
} from "reactstrap";
import { DATE_FORMAT } from "config";

class RangeDatepicker extends React.Component {
	state = {
		format: "Do/MMM/YYYY",
	};
	render() {
		return (
			<>
				<Row>
					<Col md={5}>
						<FormGroup>
							<InputGroup className="input-group-alternative">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<i className="ni ni-calendar-grid-58" />
									</InputGroupText>
								</InputGroupAddon>
								<ReactDatetime
									isValidDate={(current) => {
										return current.isSameOrBefore(
											moment().endOf("day")
										);
									}}
									inputProps={{
										placeholder: "From",
									}}
									dateFormat={this.state.format}
									timeFormat={false}
									renderDay={(
										props,
										currentDate,
										selectedDate
									) => {
										let classes = props.className;
										if (
											this.state.startDate &&
											this.state.endDate &&
											this.state.startDate._d + "" ===
												currentDate._d + ""
										) {
											classes += " start-date";
										} else if (
											this.state.startDate &&
											this.state.endDate &&
											new Date(
												this.state.startDate._d + ""
											) < new Date(currentDate._d + "") &&
											new Date(
												this.state.endDate._d + ""
											) > new Date(currentDate._d + "")
										) {
											classes += " middle-date";
										} else if (
											this.state.endDate &&
											this.state.endDate._d + "" ===
												currentDate._d + ""
										) {
											classes += " end-date";
										}
										return (
											<td {...props} className={classes}>
												{currentDate.date()}
											</td>
										);
									}}
									onChange={(e) => {
										this.setState({ startDate: e });
										if (this.props.handleChangeFrom)
											this.props.handleChangeFrom(
												e.format(DATE_FORMAT)
											);
									}}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
					<Col md={5}>
						<FormGroup>
							<InputGroup className="input-group-alternative">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<i className="ni ni-calendar-grid-58" />
									</InputGroupText>
								</InputGroupAddon>
								<ReactDatetime
									inputProps={{
										placeholder: "To",
									}}
									dateFormat={this.state.format}
									timeFormat={false}
									isValidDate={(current) => {
										return current.isSameOrBefore(
											moment().endOf("day")
										);
									}}
									renderDay={(
										props,
										currentDate,
										selectedDate
									) => {
										let classes = props.className;
										if (
											this.state.startDate &&
											this.state.endDate &&
											this.state.startDate._d + "" ===
												currentDate._d + ""
										) {
											classes += " start-date";
										} else if (
											this.state.startDate &&
											this.state.endDate &&
											new Date(
												this.state.startDate._d + ""
											) < new Date(currentDate._d + "") &&
											new Date(
												this.state.endDate._d + ""
											) > new Date(currentDate._d + "")
										) {
											classes += " middle-date";
										} else if (
											this.state.endDate &&
											this.state.endDate._d + "" ===
												currentDate._d + ""
										) {
											classes += " end-date";
										}
										return (
											<td {...props} className={classes}>
												{currentDate.date()}
											</td>
										);
									}}
									onChange={(e) => {
										this.setState({ endDate: e });
										if (this.props.handleChangeTo)
											this.props.handleChangeTo(
												e.format(DATE_FORMAT)
											);
									}}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
					<Col md={2}>
						<Button
							color="default"
							type="button"
							onClick={this.props.handleClick}
						>
							Submit
						</Button>
					</Col>
				</Row>
			</>
		);
	}
}

export default RangeDatepicker;
