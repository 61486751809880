import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar";
import AdminFooter from "components/Footers/AdminFooter.js";
import Alert from "layouts/Alert";
import routes from "routes.js";

const Admin = (props) => {
	const mainContent = React.useRef(null);
	const location = useLocation();

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === "/admin") {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};

	return (
		<>
			<AuthNavbar
				routes={routes}
				logo={{
					innerLink: "/admin/index",
					imgSrc: require("../assets/img/brand/icon128.png").default,
					imgAlt: "Side Kick",
				}}
			/>
			<div className="main-content" ref={mainContent}>
				<Alert />
				<Switch>
					{getRoutes(routes)}
					<Redirect from="*" to="/admin/dashboard" />
				</Switch>
				<Container fluid>
					<AdminFooter />
				</Container>
			</div>
		</>
	);
};

export default Admin;
