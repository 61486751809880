import axios from "axios";
import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
} from "../types";
import { setAlert } from "./alert";
import setAuthToken from "../../utils/setAuthToken";
import { BACKEND_URL, LOGIN_API_KEY, SIGNUP_API_KEY } from "config";

/**
 *  Load user
 *
 */
export const loadUser = () => async (dispatch) => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get(`${BACKEND_URL}/api/sidekick/load-user`);

		dispatch({
			type: USER_LOADED,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: AUTH_ERROR,
		});
	}
};

/**
 *  Register User
 */
export const register =
	({ name, email, password, secret }) =>
	async (dispatch) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				"x-functions-key": SIGNUP_API_KEY,
			},
		};

		const body = JSON.stringify({
			name,
			email,
			password,
			secret,
		});

		try {
			const res = await axios.post(
				`${BACKEND_URL}/api/sidekick/signup`,
				body,
				config
			);

			dispatch({
				type: REGISTER_SUCCESS,
				payload: res.message,
			});
			dispatch(setAlert(res.data.message, "success"));
		} catch (err) {
			const error = err.response.data;

			if (error) {
				dispatch(setAlert(error.message, "danger"));
			}
			dispatch({
				type: REGISTER_FAIL,
			});
		}
	};

/**
 *  Login User
 */
export const login = (email, password) => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
			"x-functions-key": LOGIN_API_KEY,
		},
	};

	const body = JSON.stringify({ email, password });

	try {
		const res = await axios.post(
			`${BACKEND_URL}/api/sidekick/signin`,
			body,
			config
		);

		dispatch({
			type: LOGIN_SUCCESS,
			payload: res.data,
		});

		dispatch(loadUser());
	} catch (err) {
		const error = err.response.data;

		if (error) {
			dispatch(setAlert(error.message, "danger"));
		}
		dispatch({
			type: LOGIN_FAIL,
		});
	}
};

/**
 * Logout
 *
 */
export const logout = () => (dispatch) => {
	dispatch({
		type: LOGOUT,
	});
};
