import {
	SALESFORCE_OAUTH2_START,
	SALESFORCE_GENERATE_TOKENS,
	DASHBOARD_ERROR,
} from "../types";

const initialState = {
	salesforceOauthResponse: null,
	generateSalesforceTokensResponse: null,
	error: null,
};

export default function dashboard(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case SALESFORCE_OAUTH2_START:
			return {
				...state,
				salesforceOauthResponse: payload,
			};
		case SALESFORCE_GENERATE_TOKENS:
			return {
				...state,
				generateSalesforceTokensResponse: payload,
			};
		case DASHBOARD_ERROR:
			return {
				...state,
				error: payload,
			};
		default:
			return state;
	}
}
