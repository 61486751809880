import React, { Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./store/actions/auth";
import setAuthToken from "./utils/setAuthToken";

import AdminLayout from "layouts/Admin";
import PrivateRoute from "components/Routing/PrivateRoute";
import Login from "views/Login";
import Register from "views/Signup";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          {/* <Navbar /> */}
          <section>
            <Switch>
              <Route exact path="/register" component={Register}></Route>
              <Route exact path="/login" component={Login}></Route>
              <Redirect exact from="/" to="/login" />
              <PrivateRoute
                path="/admin"
                component={(props) => <AdminLayout {...props} />}
              />
            </Switch>
          </section>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
