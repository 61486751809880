import { GET_REPORT, REPORT_ERROR, UPDATE_EMAIL_LIST_NAMES } from "../types";

const initialState = {
	error: null,
	updateEmailListNamesResponse: null,
	reportResponse: null,
};

export default function report(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case UPDATE_EMAIL_LIST_NAMES:
			return {
				...state,
				updateEmailListNamesResponse: payload,
			};
		case REPORT_ERROR:
			return {
				...state,
				error: payload,
			};
		case GET_REPORT:
			return {
				...state,
				reportResponse: payload,
			};
		default:
			return state;
	}
}
