import { SET_ALERT, REMOVE_ALERT } from "../types";
import { v4 as uuid } from "uuid";

// We are able to dispatch here because of the thunk middleware
export const setAlert =
	(msg, alertType, timeout = 5000) =>
	(dispatch) => {
		const id = uuid(); // We are using version 4 of uuid that will generate a random long string
		dispatch({
			type: SET_ALERT,
			payload: {
				msg,
				alertType,
				id,
			},
		});

		setTimeout(
			() => dispatch({ type: REMOVE_ALERT, payload: id }),
			timeout
		);
	};
