import Dashboard from "views/Dashboard";
import Report from "views/Report";

const routes = [
	{
		path: "/dashboard",
		name: "Dashboard",
		icon: "fas fa-user-shield",
		component: Dashboard,
		layout: "/admin",
	},
	{
		path: "/reports",
		name: "Reports",
		icon: "fas fa-chart-bar",
		component: Report,
		layout: "/admin",
	},
];
export default routes;
