import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	UncontrolledCollapse,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Row,
	Col,
} from "reactstrap";
import { logout } from "store/actions/auth";

const AdminNavbar = ({ logo, routes }) => {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	return (
		<>
			<Navbar
				className="navbar-top navbar-horizontal navbar-light px-5"
				expand="md"
			>
				<NavbarBrand to="/" tag={Link}>
					<img
						alt={logo.imgAlt}
						src={logo.imgSrc}
						style={{ height: 40 }}
					/>
				</NavbarBrand>
				<button className="navbar-toggler" id="navbar-collapse-main">
					<span className="navbar-toggler-icon" />
				</button>
				<UncontrolledCollapse navbar toggler="#navbar-collapse-main">
					<div className="navbar-collapse-header d-md-none">
						<Row>
							<Col className="collapse-brand" xs="6">
								<Link to="/">
									<img alt={logo.imgAlt} src={logo.imgSrc} />
								</Link>
							</Col>
							<Col className="collapse-close" xs="6">
								<button
									className="navbar-toggler"
									id="navbar-collapse-main"
								>
									<span />
									<span />
								</button>
							</Col>
						</Row>
					</div>
					<Nav className="ml-auto" navbar>
						{user?.name && (
							<NavItem key="name">
								<NavLink className="nav-link-icon">
									<i className="fas fa-solid fa-user" />
									<span className="nav-link-inner--text">
										Hi {user.name}!
									</span>
								</NavLink>
							</NavItem>
						)}
						{routes.map((item, index) => {
							const { layout, path, name, icon } = item;
							return (
								<NavItem key={index}>
									<NavLink
										className="nav-link-icon"
										to={layout + path}
										tag={Link}
									>
										<i className={icon} />
										<span className="nav-link-inner--text">
											{name}
										</span>
									</NavLink>
								</NavItem>
							);
						})}
						<NavItem key="logout">
							<NavLink
								className="nav-link-icon"
								onClick={() => dispatch(logout())}
								tag={Link}
							>
								<i className="fas fa-sign-out-alt" />
								<span className="nav-link-inner--text">
									Logout
								</span>
							</NavLink>
						</NavItem>
					</Nav>
				</UncontrolledCollapse>
			</Navbar>
		</>
	);
};

export default AdminNavbar;
