import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import ReportTable from "./ReportTable";
import { getReport } from "store/actions/report";

const Report = () => {
	const dispatch = useDispatch();

	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState();

	const { reportResponse } = useSelector((state) => state.report);
	const report = reportResponse?.report || [];

	useEffect(() => {
		onGetReport();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const addName = () => {
	// 	if (user?.emailListNames?.includes(name)) {
	// 		dispatch(setAlert("List already exists", "danger"));
	// 		return;
	// 	}
	// 	const params = {
	// 		emailListName: name,
	// 		isAdded: true,
	// 	};
	// 	dispatch(updateEmailListNames(params));
	// 	setName("");
	// };

	const onGetReport = () => {
		dispatch(getReport(fromDate, toDate));
	};

	// const removeName = (item) => {
	// 	const params = {
	// 		emailListName: item,
	// 		isAdded: false,
	// 	};
	// 	dispatch(updateEmailListNames(params));
	// };
	return (
		<Container className="mt-4" fluid>
			{/* <Row>
				<Col>
					<EmailLists
						name={name}
						setName={setName}
						addName={addName}
						user={user}
						removeName={removeName}
					/>
				</Col>
			</Row> */}
			<Row className="mt-3">
				<Col>
					<ReportTable
						setFromDate={setFromDate}
						setToDate={setToDate}
						onSubmit={onGetReport}
						report={report}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default Report;
