import React, { useState } from "react";
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../store/actions/auth";
import Alert from "layouts/Alert";

const Signup = () => {
	const dispatch = useDispatch();
	const { isAuthenticated } = useSelector((state) => state.auth);
	const [data, setData] = useState({
		name: "",
		email: "",
		password: "",
		secret: "",
	});
	const handleChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const onSubmit = async () => {
		dispatch(register(data));
	};

	if (isAuthenticated) {
		return <Redirect to="/admin" />;
	}

	return (
		<div className="d-flex justify-content-center mt-5">
			<Col lg="6" md="8">
				<Alert />
				<Card className="bg-secondary shadow border-0">
					<CardBody className="px-lg-5 py-lg-5">
						<div className="text-center text-muted mb-4">
							<small>Sign up</small>
						</div>
						<Form role="form">
							<FormGroup>
								<InputGroup className="input-group-alternative mb-3">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-hat-3" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Name"
										type="text"
										value={data.name}
										onChange={handleChange}
										name="name"
									/>
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<InputGroup className="input-group-alternative mb-3">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-email-83" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Email"
										type="email"
										value={data.email}
										onChange={handleChange}
										name="email"
									/>
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-lock-circle-open" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Password"
										type="password"
										value={data.password}
										onChange={handleChange}
										name="password"
									/>
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-lock-circle-open" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Secret"
										type="password"
										value={data.secret}
										onChange={handleChange}
										name="secret"
									/>
								</InputGroup>
							</FormGroup>
						</Form>
						<div className="text-center">
							<Button
								className="mt-4"
								color="primary"
								type="button"
								disabled={
									!data.email ||
									!data.password ||
									!data.name ||
									!data.secret
								}
								onClick={onSubmit}
							>
								Create account
							</Button>
						</div>
					</CardBody>
				</Card>
				<Row className="mt-3">
					<Col xs="6">
						<a
							className="text-light"
							href="#pablo"
							onClick={(e) => e.preventDefault()}
						>
							<small>
								<Link to="/login">
									Login to existing account
								</Link>
							</small>
						</a>
					</Col>
				</Row>
			</Col>
		</div>
	);
};

export default Signup;
