import React from "react";
import RangeDatepicker from "components/RangeDatepicker";
import {
	Card,
	CardHeader,
	CardBody,
	Form,
	Row,
	Col,
	Progress,
	Table,
} from "reactstrap";

const ReportTable = ({ setFromDate, setToDate, onSubmit, report }) => {
	return (
		<Card className="bg-secondary shadow">
			<CardHeader className="bg-white border-0">
				<Row className="align-items-center">
					<Col xs="8">
						<h3 className="mb-0">Report</h3>
					</Col>
				</Row>
			</CardHeader>
			<CardBody>
				<Form>
					<h6 className="heading-small text-muted mb-4">
						Email campaign report
					</h6>
					<div className="pl-lg-4">
						<Row>
							<Col>
								<RangeDatepicker
									handleChangeFrom={(date) =>
										setFromDate(date)
									}
									handleChangeTo={(date) => setToDate(date)}
									handleClick={onSubmit}
								/>
							</Col>
						</Row>

						<Row>
							<Table className="align-items-center" responsive>
								<thead className="thead-light">
									<tr>
										<th scope="col">Campaign</th>
										<th scope="col">Sent</th>
										<th scope="col">Opened</th>
										<th scope="col">Opened (%)</th>
										<th scope="col">Clicks</th>
										<th scope="col">Clicks (%)</th>
										<th scope="col">Replies</th>
										<th scope="col">Replies (%)</th>
										<th scope="col">Bounced</th>
										<th scope="col">Bounced (%)</th>
										<th scope="col">Unsubscribed</th>
										<th scope="col">Unsubscribed (%)</th>
									</tr>
								</thead>
								<tbody>
									{report?.length > 0 &&
										report.map((item) => (
											<tr key={item.campaign}>
												<td>{item.campaign}</td>
												<td>
													{item.counts
														.new_email_sent || 0}
												</td>
												<td>
													{item.counts
														.new_email_open || 0}
												</td>
												<td>
													<div className="d-flex align-items-start flex-column justify-content-center">
														<span>
															{item.counts
																.new_email_open_percentage ||
																0}
															%
														</span>
														<div>
															<Progress
																max="100"
																value={
																	item.counts
																		.new_email_open_percentage ||
																	0
																}
																barClassName="bg-success"
																animated
																striped
																style={{
																	width: "64px",
																}}
															/>
														</div>
													</div>
												</td>
												<td>
													{item.counts
														.new_email_click || 0}
												</td>
												<td>
													<div className="d-flex align-items-start flex-column justify-content-center">
														<span>
															{item.counts
																.new_email_click_percentage ||
																0}
															%
														</span>
														<div>
															<Progress
																max="100"
																value={
																	item.counts
																		.new_email_click_percentage ||
																	0
																}
																barClassName="bg-success"
																animated
																striped
																style={{
																	width: "64px",
																}}
															/>
														</div>
													</div>
												</td>
												<td>
													{item.counts
														.new_email_reply || 0}
												</td>
												<td>
													<div className="d-flex align-items-start flex-column justify-content-center">
														<span>
															{item.counts
																.new_email_reply_percentage ||
																0}
															%
														</span>
														<div>
															<Progress
																max="100"
																value={
																	item.counts
																		.new_email_reply_percentage ||
																	0
																}
																barClassName="bg-success"
																animated
																striped
																style={{
																	width: "64px",
																}}
															/>
														</div>
													</div>
												</td>
												<td>
													{item.counts
														.new_email_bounce || 0}
												</td>
												<td>
													<div className="d-flex align-items-start flex-column justify-content-center">
														<span>
															{item.counts
																.new_email_bounce_percentage ||
																0}
															%
														</span>
														<div>
															<Progress
																max="100"
																value={
																	item.counts
																		.new_email_bounce_percentage ||
																	0
																}
																barClassName="bg-success"
																animated
																striped
																style={{
																	width: "64px",
																}}
															/>
														</div>
													</div>
												</td>
												<td>
													{item.counts
														.new_email_unsubscribe ||
														0}
												</td>
												<td>
													<div className="d-flex align-items-start flex-column justify-content-center">
														<span>
															{item.counts
																.new_email_unsubscribe_percentage ||
																0}
															%
														</span>
														<div>
															<Progress
																max="100"
																value={
																	item.counts
																		.new_email_unsubscribe_percentage ||
																	0
																}
																barClassName="bg-success"
																animated
																striped
																style={{
																	width: "64px",
																}}
															/>
														</div>
													</div>
												</td>
											</tr>
										))}
								</tbody>
							</Table>
						</Row>
					</div>
				</Form>
			</CardBody>
		</Card>
	);
};

export default ReportTable;
