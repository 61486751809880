export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const DASHBOARD_ERROR = "DASHBOARD_ERROR";
export const SALESFORCE_OAUTH2_START = "SALESFORCE_OAUTH2_START";
export const SALESFORCE_GENERATE_TOKENS = "SALESFORCE_GENERATE_TOKENS";

export const REPORT_ERROR = "REPORT_ERROR";
export const UPDATE_EMAIL_LIST_NAMES = "UPDATE_EMAIL_LIST_NAMES";
export const GET_REPORT = "GET_REPORT";
