import axios from "axios";
import { GET_REPORT, REPORT_ERROR, UPDATE_EMAIL_LIST_NAMES } from "../types";
import { setAlert } from "./alert";
import { BACKEND_URL } from "config";
import { loadUser } from "./auth";

export const updateEmailListNames = (data) => async (dispatch) => {
	try {
		const res = await axios.put(
			`${BACKEND_URL}/api/sidekick/update-email-list`,
			data
		);
		dispatch({
			type: UPDATE_EMAIL_LIST_NAMES,
			payload: res.data,
		});
		dispatch(loadUser());
	} catch (err) {
		dispatch(setAlert(err.message, "danger"));
		dispatch({
			type: REPORT_ERROR,
			payload: err,
		});
	}
};

export const getReport = (from, to) => async (dispatch) => {
	try {
		let url = `${BACKEND_URL}/api/sidekick/report?`;
		if (from) url += `from=${from}&`;
		if (to) url += `to=${to}`;

		const res = await axios.get(url);
		dispatch({
			type: GET_REPORT,
			payload: res.data,
		});
		dispatch(loadUser());
	} catch (err) {
		dispatch(setAlert(err.message, "danger"));
		dispatch({
			type: REPORT_ERROR,
			payload: err,
		});
	}
};
