import axios from "axios";
import {
	SALESFORCE_OAUTH2_START,
	SALESFORCE_GENERATE_TOKENS,
	DASHBOARD_ERROR,
} from "../types";
import { setAlert } from "./alert";
import { BACKEND_URL } from "config";

export const salesforceOauth2 = () => async (dispatch) => {
	try {
		const res = await axios.post(
			`${BACKEND_URL}/api/sidekick/salesforce-oauth2-start`
		);
		dispatch({
			type: SALESFORCE_OAUTH2_START,
			payload: res.data,
		});
		if (res?.data?.oauth2Url) {
			window.open(res.data.oauth2Url);
		}
	} catch (err) {
		dispatch(setAlert(err.message, "danger"));
		dispatch({
			type: DASHBOARD_ERROR,
			payload: err,
		});
	}
};

export const salesforceGenerateTokens = (code) => async (dispatch) => {
	try {
		const res = await axios.get(
			`${BACKEND_URL}/api/sidekick/salesforce-oauth2?code=${code}`
		);
		dispatch({
			type: SALESFORCE_GENERATE_TOKENS,
			payload: res.data,
		});
		dispatch(setAlert(res.data.message, "success"));
	} catch (err) {
		dispatch(setAlert(err.message, "danger"));
		dispatch({
			type: DASHBOARD_ERROR,
			payload: err,
		});
	}
};
