import React from "react";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";

const CustomAlert = () => {
  const alerts = useSelector((state) => state.alert);
  const getTitle = (type) => {
    if (type === "success") return "Success!";
    if (type === "danger") return "Error!";
  };
  return alerts?.length
    ? alerts.map((alert) => (
        <Alert key={alert.id} color={alert.alertType} className="m-2">
          <strong>{getTitle(alert.alertType)}</strong> {alert.msg}
        </Alert>
      ))
    : "";
};

export default CustomAlert;
